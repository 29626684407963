import { h } from 'hyperapp';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import TopicChip from '../topic-chip/view.jsx';

const Title = () => h("div", {
  class: "mdui-dialog-title"
}, "\u8BF7\u9009\u62E9\u8BDD\u9898", h("button", {
  class: "mdui-btn mdui-btn-icon mdui-ripple close",
  "mdui-dialog-close": true
}, h("i", {
  class: "mdui-icon material-icons"
}, "close")));
/**
 * @param state 包含 stateAbstract 中的 editorState 和 topicSelectorState
 * @param actions 包含 actionsAbstract 中的 editorActions 和 topicSelectorActions
 */


export default (({
  state,
  actions
}) => {
  const {
    topics_data: data,
    topics_pagination: pagination,
    topics_loading: loading,
    editor_selected_topics: selected,
    editor_selected_topic_ids: selected_ids
  } = state;
  const isEmpty = !loading && !data.length && pagination;
  const isLoading = loading;
  const isLoaded = !loading && pagination && pagination.page === pagination.pages;

  const Selected = () => selected.length ? h("div", {
    class: "selected",
    key: "selected"
  }, selected.map((topic, selectedIndex) => h(TopicChip, {
    topic: topic,
    onRemove: () => {
      actions.topicSelectorRemoveOne(selectedIndex);
    }
  }))) : null;

  const Item = ({
    topic,
    dataIndex
  }) => h("label", {
    class: "mdui-list-item",
    key: topic.topic_id
  }, h("div", {
    class: "mdui-list-item-avatar"
  }, h("img", {
    src: topic.cover.small
  })), h("div", {
    class: "mdui-list-item-content"
  }, h("div", {
    class: "mdui-list-item-title mdui-list-item-one-line"
  }, topic.name), h("div", {
    class: "mdui-list-item-text mdui-list-item-one-line"
  }, topic.description)), h("div", {
    class: "mdui-checkbox"
  }, h("input", {
    type: "checkbox",
    checked: selected_ids.indexOf(topic.topic_id) > -1,
    onchange: event => {
      actions.topicSelectorChange({
        event,
        dataIndex
      });
    }
  }), h("i", {
    class: "mdui-checkbox-icon"
  })));

  return h("div", {
    key: "topic-selector",
    class: "mdui-dialog mc-topic-selector"
  }, h(Title, null), h("div", {
    class: "mdui-dialog-content"
  }, h(Selected, null), h("div", {
    class: "mdui-list",
    key: "list"
  }, data.map((topic, dataIndex) => h(Item, {
    topic: topic,
    dataIndex: dataIndex
  }))), h(Empty, {
    show: isEmpty,
    title: "\u7BA1\u7406\u5458\u672A\u53D1\u5E03\u4EFB\u4F55\u8BDD\u9898",
    description: "\u5F85\u7BA1\u7406\u5458\u53D1\u5E03\u8BDD\u9898\u540E\uFF0C\u4F1A\u663E\u793A\u5728\u6B64\u5904"
  }), h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  })), h("div", {
    class: "mdui-dialog-actions"
  }, h("button", {
    class: "mdui-btn mdui-ripple",
    "mdui-dialog-confirm": true
  }, "\u786E\u5B9A")));
});