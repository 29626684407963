import { h } from 'hyperapp';
import { isPathArticles, isPathQuestions, isPathTopics, isPathUsers } from '~/utils/path';
import Tab from '~/components/tab/view.jsx';
export default (({
  user
}) => isPathQuestions() ? h(Tab, {
  key: "questions",
  centered: true,
  items: [{
    name: '最新',
    hash: 'recent'
  }, {
    name: '近期热门',
    hash: 'popular'
  }]
}) : isPathArticles() ? h(Tab, {
  key: "articles",
  centered: true,
  items: [{
    name: '最新',
    hash: 'recent'
  }, {
    name: '近期热门',
    hash: 'popular'
  }] // .concat(user ? { name: '已关注', hash: 'following' } : null)

}) : user && isPathTopics() ? h(Tab, {
  key: "topics",
  centered: true,
  items: [// { name: '已关注', hash: 'following' },
  {
    name: '精选',
    hash: 'recommended'
  }]
}) : null);