import { h } from 'hyperapp';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Loaded from '~/components/loaded/view.jsx';
import Empty from '~/components/empty/view.jsx';
import Follow from '~/components/follow/view.jsx';

const Title = ({
  state
}) => h("div", {
  class: "mdui-dialog-title"
}, h("button", {
  class: "close mdui-btn mdui-btn-icon mdui-ripple",
  "mdui-dialog-close": true
}, h("i", {
  class: "mdui-icon material-icons"
}, "close")));

const Item = ({
  item,
  actions
}) => h("div", {
  class: "item"
}, h("div", {
  class: "mdui-list-item mdui-ripple",
  key: item.user_id,
  onclick: () => actions.onItemClick(item.user_id)
}, h("div", {
  class: "mdui-list-item-avatar"
}, h("img", {
  src: item.avatar.middle
})), h("div", {
  class: "mdui-list-item-content"
}, item.username)), !item.relationships.is_me ? null : null);

export default (({
  state,
  actions
}) => {
  const isEmpty = !state.loading && !state.data.length && state.pagination;
  const isLoading = state.loading;
  const isLoaded = !state.loading && state.pagination && state.pagination.page === state.pagination.pages;
  return h("div", {
    key: "users-dialog",
    class: "mdui-dialog mc-users-dialog",
    oncreate: element => actions.onCreate({
      element
    })
  }, h(Title, {
    state: state
  }), h("div", {
    class: "mdui-dialog-content"
  }, h("div", {
    class: "mdui-list"
  }, state.data.map(item => h(Item, {
    item: item,
    actions: actions
  }))), h(Empty, {
    show: isEmpty,
    title: state.type === 'followees' ? '尚未关注任何用户' : '没有任何关注者'
  }), h(Loading, {
    show: isLoading
  }), h(Loaded, {
    show: isLoaded
  })));
});