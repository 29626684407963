import { h } from 'hyperapp';
import { Link } from 'hyperapp-router';
import { fullPath } from '~/utils/path';
import { emit } from '~/utils/pubsub';
import { summaryText } from '~/utils/html';
import './index.less';
import Follow from '~/components/follow/view.jsx';
export default (({
  topic,
  actions,
  type = 'topics'
}) => h("div", {
  class: "mc-topic-item item-inner",
  key: topic.topic_id
}, h("div", {
  class: "item mdui-card",
  style: {
    backgroundImage: `url("${topic.cover.small}")`
  }
}, h(Link, {
  to: fullPath(`/topics/${topic.topic_id}`),
  class: "mdui-ripple info",
  onclick: () => actions.afterItemClick(topic)
}, h("div", {
  class: "name mdui-text-color-theme-text",
  oncreate: summaryText(topic.name),
  onupdate: summaryText(topic.name)
})))));