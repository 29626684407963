import { h } from 'hyperapp';
import './index.less';
import Header from './components/header/view.jsx';
import Topics from './components/topics/view.jsx';
import Items from './components/items/view.jsx';
export default ((state, actions) => ({
  match
}) => h("div", {
  oncreate: actions.onCreate,
  ondestroy: actions.onDestroy,
  key: match.url,
  id: "page-index",
  class: "mdui-container"
}, h(Header, {
  title: "\u63A8\u8350\u8BDD\u9898",
  url: "/topics#recommended"
}), h(Topics, {
  state: state,
  actions: actions
}), h("div", {
  class: "items-wrapper"
}, h(Items, {
  title: "\u6700\u65B0\u6587\u7AE0",
  items: state.articles_recent_data,
  primaryKey: "article_id",
  dataName: "articles_recent_data",
  loading: state.articles_recent_loading,
  url: "/articles",
  actions: actions
}), h(Items, {
  title: "\u6700\u8FD1\u70ED\u95E8\u6587\u7AE0",
  items: state.articles_popular_data,
  primaryKey: "article_id",
  dataName: "articles_popular_data",
  loading: state.articles_popular_loading,
  url: "/articles#popular",
  actions: actions
}))));