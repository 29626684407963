import { h } from 'hyperapp';
import { emit } from '~/utils/pubsub';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import Follow from '~/components/follow/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';
import { summaryText } from '~/utils/html';
export default (({
  topic,
  loading,
  actions
}) => h("div", {
  class: "mdui-card mdui-card-shadow topic"
}, topic ? h("div", {
  class: "info"
}, h("div", {
  class: "cover",
  style: {
    backgroundImage: `url("${topic.cover.small}")`
  }
}), h("div", {
  class: "main"
}, h("div", {
  class: "name",
  oncreate: summaryText(topic.name),
  onupdate: summaryText(topic.name)
}), h("div", {
  class: "meta mdui-text-color-theme-secondary"
}, h("span", null, topic.article_count, " \u7BC7\u6587\u7AE0")), h("div", {
  class: "description mdui-text-color-theme-secondary",
  oncreate: summaryText(topic.description),
  onupdate: summaryText(topic.description)
}))) : null, h(Loading, {
  show: loading
})));