import { h } from 'hyperapp';
import cc from 'classcat';
import $ from 'mdui.jq';
import { $window } from 'mdui/es/utils/dom';
import { emit } from '~/utils/pubsub';
import { summaryText, plainText } from '~/utils/html';
import './index.less';
import Loading from '~/components/loading/view.jsx';
import AvatarUpload from '~/components/avatar-upload/view.jsx';
import CoverUpload from '~/components/cover-upload/view.jsx';
import Follow from '~/components/follow/view.jsx';
import OptionsButton from '~/components/options-button/view.jsx';

const MetaItem = ({
  icon,
  text,
  tooltip,
  multiLine = false
}) => {
  const contentFunc = multiLine ? plainText(text) : summaryText(text);
  return h("div", {
    class: "meta"
  }, h("i", {
    class: "mdui-icon material-icons mdui-text-color-theme-icon",
    "mdui-tooltip": `{content: '${tooltip}', delay: 300}`
  }, icon), h("div", {
    class: cc([{
      'mdui-typo': multiLine
    }]),
    oncreate: contentFunc,
    onupdate: contentFunc
  }));
};

export default (({
  state,
  actions,
  is_me
}) => {
  const {
    interviewee
  } = state;
  return h("div", {
    key: "user",
    class: "user mdui-card mdui-card-shadow"
  }, h(Loading, {
    show: state.loading
  }), interviewee ? [h("div", {
    class: "cover",
    style: {
      backgroundImage: `url("${interviewee.cover.large}")`
    },
    oncreate: element => {
      // cover 元素创建完成后，绑定滚动事件，使封面随着滚动条滚动
      setTimeout(() => {
        $window.on('scroll', () => {
          window.requestAnimationFrame(() => {
            element.style['background-position-y'] = `${window.pageYOffset / 2}px`;
          });
        }); // 向下滚动一段距离

        window.scrollTo(0, $(element).width() * 0.56 * 0.58);
      });
    },
    key: "0"
  }, is_me ? h(CoverUpload, {
    user: interviewee
  }) : null), h("div", {
    class: "info",
    key: "1"
  }, h("div", {
    class: "avatar-box"
  }, is_me ? h(AvatarUpload, {
    user: interviewee
  }) : null, h("img", {
    src: interviewee.avatar.large,
    class: "avatar"
  })), h("div", {
    class: cc(['profile', {
      fold: state.profile_fold
    }])
  }, h("div", {
    class: "meta username mdui-text-color-theme-text"
  }, interviewee.username), interviewee.headline ? h(MetaItem, {
    icon: "credit_card",
    text: interviewee.headline,
    tooltip: "\u4E00\u53E5\u8BDD\u4ECB\u7ECD"
  }) : null, interviewee.blog ? h(MetaItem, {
    icon: "insert_link",
    text: interviewee.blog,
    tooltip: "\u4E2A\u4EBA\u4E3B\u9875"
  }) : null, interviewee.company ? h(MetaItem, {
    icon: "location_city",
    text: interviewee.company,
    tooltip: "\u6240\u5C5E\u5B66\u6821\u6216\u4F01\u4E1A"
  }) : null, interviewee.location ? h(MetaItem, {
    icon: "location_on",
    text: interviewee.location,
    tooltip: "\u6240\u5728\u5730\u533A"
  }) : null, interviewee.bio ? h(MetaItem, {
    icon: "description",
    text: interviewee.bio,
    tooltip: "\u4E2A\u4EBA\u7B80\u4ECB",
    multiLine: true
  }) : null, interviewee.blog || interviewee.company || interviewee.location || interviewee.bio ? h("button", {
    class: "fold-button mdui-btn",
    onclick: () => {
      actions.setState({
        profile_fold: !state.profile_fold
      });
    }
  }, h("i", {
    class: "mdui-icon-left mdui-icon material-icons mdui-text-color-theme-icon"
  }, state.profile_fold ? 'keyboard_arrow_down' : 'keyboard_arrow_up'), state.profile_fold ? '查看' : '收起', "\u8BE6\u7EC6\u8D44\u6599") : null)), h("div", {
    class: "actions",
    key: "2"
  }, !is_me ? null : null, is_me ? h("button", {
    class: "edit mdui-btn mdui-btn-icon mdui-btn-outlined",
    "mdui-tooltip": "{content: '\u7F16\u8F91\u4E2A\u4EBA\u8D44\u6599', delay: 300}",
    onclick: actions.updateUserInfo
  }, h("i", {
    class: "mdui-icon material-icons mdui-text-color-theme-icon"
  }, "edit")) : null, h("div", {
    class: "flex-grow"
  }), h(OptionsButton, {
    type: "user",
    item: interviewee,
    extraOptions: is_me ? [{
      name: '重置头像',
      onClick: actions.deleteAvatar
    }, {
      name: '重置封面',
      onClick: actions.deleteCover
    }] : null
  }))] : null);
});